.app_form {
  @import "../variables/desctop_sizes";

  @media (max-width: (map-get( $desktopSizes , mobile ) - 1)) {
    .ml-20 { margin-left: 0 !important;}
    .input-ml-items-3 { margin-left: 0 !important;}
  }
  @media (min-width: (map-get( $desktopSizes , mobile ))) and (max-width: (map-get( $desktopSizes , medium ))) {
    justify-content: space-between;
    .ml-20 { margin-left: 0 !important;}
    .input-ml-items-3 { margin-left: 0 !important;}
  }
}