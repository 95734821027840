@use '@angular/material' as mat;
@import "@angular/material/theming";
@include mat.core();
.mat-body, .mat-body-1, .mat-typography .mat-body,
.mat-typography .mat-body-1, .mat-typography {
  font-family: "Mulish" !important;
  font-size: 12px !important;
}
$my-primary: mat.define-palette(mat.$deep-orange-palette, 500);
$my-accent: mat.define-palette(mat.$deep-orange-palette, A200, A100, A400);
$my-theme: mat.define-light-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent,
        ),
        typography: mat.define-typography-config($font-family: 'Mulish'),
        density: 0,
));
@include mat.all-component-themes($my-theme);
