.app_input {
  position: relative;

  input, textarea {
    width: 100%;
    box-sizing: border-box;
    border-radius: 16px;
    border: none;
    background-color: var(--gray-input);
    padding: 10px 24px;
    outline: unset;
    height: 56px;

    &::placeholder {
      color: var(--gray-1);
      font-size: 14px;
      font-weight: 500;
    }

    &.with_left_icon {
      padding-left: 68px;
    }

    &.with_right_icon {
      padding-right: 68px;
    }


    &:disabled {
      background: var(--gray-input);
      color: var(--text-gray-color);
    }
  }

  .input_icon {
    display: flex;
    align-self: center;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);

    img {
      width: 24px
    }

    &.left_icon_with_border {
      left: 0;
      border-right: 1px solid var(--input-border-color);
      padding-right: 12px;
      box-sizing: border-box;
    }

    &.right_icon {
      right: 30px;
    }
  }

  .color_input {
    background-color: var(--white-color);
    border: 1px solid var(--input-border-color);
    padding-left: 10px;
    border-radius: 8px;
    outline: unset;
    position: relative;

    input[type="text"] {
      border: none;
      outline: none;
    }

    input[type="color"] {
      visibility: hidden;
      position: absolute;
      bottom: 0;
    }

    .color_view {
      width: 26px;
      height: 20px;
      border-radius: 50%;
    }
  }

  & .mat-mdc-select {
    background-color: var(--gray-input);
    border-radius: 20px;
    height: 56px;
    display: flex;
    align-items: center;
    width: 100%;

    & .mat-mdc-select-placeholder {
      color: #757575 !important;
    }

    &-disabled {
      border: 1px solid var(--input-border-colors) !important;
      background: var(--gray-input);
    }

    & .mat-mdc-select-placeholder, .mat-mdc-select-value-text {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: normal !important;
    }

    & .mat-mdc-select-trigger {
      width: 100%;
      height: 100%;
      padding: 16px !important;
    }
  }

  & .select_with_label {
    .mat-mdc-select-value-text {
      .mat-mdc-select-min-line {
        display: block;
        margin-top: 17px;
      }
    }
  }

  & .custom-panel-order-select {
    border-radius: 20px !important;
    min-width: fit-content;
    background: var(--gray-input) !important;
    border: none;
    width: 200px;
    height: 44px !important;

    & .mat-select-value-text {
      color: var(--our-black) !important;
      font-weight: 500 !important;
      font-size: 14px !important;
    }

  }

  & .table_input {
    padding: 7px 10px !important;
  }

  & .table_mat_select {
    padding: 5px !important;
  }

  & app-important-field {
    position: absolute;
    right: 4%;
    top: 1.2em;
    color: var(--red-color);
  }

  & app-error-message {
    color: var(--red-color);

    & .small_text {
      padding-top: 2px;
    }

    & .active {
      animation: active 2s ease-in;
    }

    @keyframes active {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  & .with-eye {
    padding-right: 50px;
  }
}

.border_top_solid {
  border-top: 3px solid #919191;
  width: 30px;
}


.dark_select {
  .mat-select {
    background-color: #303030 !important;

    .mat-select-placeholder, .mat-select-value-text {
      color: white !important;
    }

    .mat-select-arrow {
      border-image-source: url('../../../assets/images/icons/ic_white_dropdown.svg') !important;
    }
  }
}

.app_input.with_field_name {
  & > span {
    color: var(--enactive-text-color);
  }

  & app-important-field {
    top: 2.6em
  }
}

.span_color {
  color: var(--enactive-text-color);
}


.checkbox, .radio {
  display: block;
  height: 18px;
  width: 18px;
  position: relative;
  cursor: pointer;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & .checkmark {
    outline: 2px solid var(--rgb-gray-color-transperent);
    border: 2px solid var(--gray-input);
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 7px;
    background-color: var(--gray-input);
    transition: 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: none;
    }
  }

  & input:checked ~ .checkmark_radio {
    border-radius: 50%;
  }

  &:hover input ~ .checkmark {
    transition: 0.4s;
    background-color: var(--orange-light-color);
  }

  & input:checked ~ .checkmark {
    border: 2px solid var(--gray-input);
    outline: 1px solid var(--rgb-gray-color-transperent);
    background-color: var(--orange-light-color);
  }

  .checked {
    img {
      display: block;
      width: 80%;
    }
  }
}

.checkbox.disabled {
  & .checkmark {
    background-color: var(--disabled-green-color) !important;
  }
}

// Radio button
.radio {
  display: block;
  height: 19px;
  width: 19px;
  position: relative;
  cursor: pointer;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    border: 1px solid #B7B7B7;
    border-radius: 50%;
  }

  & input:checked ~ .checkmark_radio {
    border-radius: 50%;
  }

  &:hover input ~ .checkmark {
    background-color: var(--green-color);
  }

  & input:checked ~ .checkmark {
    background-color: var(--green-color);
  }

  & .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark_radio:after {
    display: block;
  }

  & .checkmark:after {
    left: 6.5px;
    top: 4.1px;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.radio.disabled {
  & .checkmark {
    background-color: var(--disabled-green-color) !important;
  }
}

// Single page without edit
.disabled-checkbox {
  cursor: unset;

  &:hover input ~ .checkmark {
    background-color: transparent;
  }
}

// Date Input Css

.mat-calendar-body-cell-content, .mat-calendar-body-label, .mat-calendar-period-button {
  font-size: 10px !important;
}

.mat-datepicker-content .mat-calendar {
  width: 200px !important;
  height: auto !important;
}

.ngx-mat-timepicker form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-calendar-controls {
  margin: 0 calc(33% / 7 - 16px) !important;
}

.mat-calendar-body-selected,
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--orange-color) !important;
  color: var(--white-color);
  border-color: var(--orange-color);
  box-shadow: inset 0 0 0 1px white;
}

// Date Input Css

.inActive {
  input {
    border: 1px solid rgba(232, 87, 73, 0.4) !important;
  }

  textarea {
    border: 1px solid rgba(232, 87, 73, 0.4) !important;
  }

  .mat-mdc-select {
    border: 1px solid rgba(232, 87, 73, 0.4) !important;
  }
}

// End of Material Autocomplete css changes


@import "../variables/desctop_sizes";

@media (max-width: (map-get( $desktopSizes , mobile ) - 1)) {
  .app_input {
    width: 100%;
  }
}

@media (min-width: (map-get( $desktopSizes , large_3 ) - 1)) {
  .app_input {
    & input {
      padding: 22px 16px;
      font-size: 14px
    }

    & .mat-select {
      padding: 22px 16px;
      font-size: 14px
    }

    & app-important-field {
      top: 2.4em
    }
  }
  .app_input.with_field_name {
    & app-important-field {
      top: 3.8em
    }
  }
}

.hidden_input {
  display: inherit !important;
  padding: 0 !important;
  margin: 0;
  border: 0 !important;
  height: 0;
}

.mat-form-field-appearance-fill {
  .mat-form-field-flex {
    background-color: unset !important;
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-flex {
    //margin-top: unset !important;
  }
}

.mat-datepicker-toggle {
  .mat-button-wrapper {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url('../../images/icon/input/date-picker-calendar.svg');
    background-repeat: no-repeat;

    svg {
      display: none !important;
    }
  }
}

.mat-datepicker-toggle-active {
  color: var(--orange-color);
}

.filtration_component {
  .app_input {
    width: 250px !important;
    margin-right: 30px;
  }

  app-mat-range-datepicker {
    margin-right: 30px;
  }
}

