:root {
  --box-shadow-color: #00000014;
  --box-shadow-color-bold: #00000017;
  --box-shadow-color-very-bold: #00000029;
  --blue-color: #0071BF;
  --purple-color: #AC00AC;
  --red-color: #ff0000;
  --red-blur-color: #7c4a4a;
  --green-color: #00AC47;
  --green-approve-color: #54B644;
  --light-green-color: #21c966;
  --disabled-green-color: #00ac4775;
  --green-sidebar-color: #00c658;
  --black-color: #000000;
  --white-color: #fdfdfd;
  --calendar-green-color: #D3ECDD66;
  --gray-color: #E9E7E7;
  --gray-color-calendar: #DCDCDC63;
  --blue-color-calendar: #D3DBEC66;
  --blue-color-calendar-border: #001DAC;
  --gray-icon-color: #707070;
  --table-background-gray-color: #f5f5f5;
  --enactive-text-color: #C1C1C1;
  --body-color: #FCFCFE;
  --input-border-color: #C8C8C8;
  --odd-gray-color: #fbfbfb;
  --pagination-gray-color: #f3f3f3;
  --sidebar-boxshadow: #00000012;
  --text-gray-color: #9F9F9F;
  --el-blue: #2F3DB5;
  --rule-back: #F8F8FB;
  --orange-light-color: #E85749;
  --warning-color: #E89749;
  ////
  --orange-color: #FE511A;
  --light-orange-color: #e76e47;
  --gray-row-color: rgba(0, 0, 0, 0.1);
  //seller
  --seller-login-bg-color: #F8F8FA;
  --gray-input: #F7F8F9;
  --system-error-red: #C5493D;
  --system-red-input: rgba(197, 73, 61, 0.03);
  --input-red: #FDEEED;
  --rgb-gray-color-transperent: rgba(30, 30, 30, 0.2);
  --rgb-48-1: rgba(48, 48, 48, 0.1);
  --gray-2: #D2D2D2;
  --gray-3: #EDEDED;
  --gray-1: #979797;
  --dissabled-red: #ECA39D;
  --our-black: #303030;
  --input-blue: #F6FBFF;
  --rgb-transperent-1: rgba(0, 0, 0, 0.1);
  --text-green-color: #44A417;
  --red-color-info: rgba(196, 73, 61, 0.10);
  --orange-color-info: rgba(232, 151, 73, 0.10);
  --bright-red-color: #FF4230;
  --status-description: #525252,
}
