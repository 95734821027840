// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import "@angular/material/theming";

$custom-typography: mat.define-typography-config(
        $font-family: 'Mulish'
);

@include mat.all-component-typographies($custom-typography);
@include mat.core();
/* You can add global styles to this file, and also import other style files */
@import "assets/scss/main";
//@import "thames";

html, body { height: 100%; }

body {
  margin: 0;
}

* {
  font-family: Mulish;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-Bold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-Bold.ttf');
  font-weight: 900;
}