.flex_between_align_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex_center_align_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.flex_between {
  display: flex;
  justify-content: space-between;
}

.flex_space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex_align_center {
  display: flex;
  align-items: center;
}

.flex_around_align_center {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex_center_align_around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex {
  display: flex;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_column_between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex_column_align_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex_column_center_align_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex_column_between_align_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.flex_column_around_align_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.flex_end {
  display: flex;
  justify-content: flex-end;
}

.flex_end_align_center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
//grid

.grid_template{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 340px;
  grid-column-gap: 40px;
}
