.ngx-otp-input {
  width: 44px !important;
  height: 56px !important;
  border-radius: 12px !important;
  border: 1px solid var(--input-border-color) !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  background-color: var(--white-color);
}

.ngx-otp-input-container {
  width: 336px;
  justify-content: space-between;
}

ngx-otp-input {
  .my-super-error-class {
    background-color: var(--system-red-input);
    border: 1px solid var(--system-error-red) !important;
    color: var(--system-error-red) !important;;
  }
}
