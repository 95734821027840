.show {visibility: visible}
.drop_down_icon {width: 10px;height: 10px; cursor: pointer}
.drop_down_icon_white {width: 15px;height: 15px; cursor: pointer}

// Image
.image_background_center {
    background-position: center;
    background-repeat: no-repeat;
}
.image_cover {
    background-size: cover;
}

.image_dropdown_icon {
    width: 15px;
    height: 15px;
}
// END of Image

.hidden {
    display: none !important;
}

.split-row {
    width: 97%;
    margin: 20px auto;
    height: 2px;
    border: 1px;
    background-color: var(--black-color);
}

.position_relative {
    position: relative;
}

.position_center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.border_none{
    border: none !important;
}