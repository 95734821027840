// main colors.scss
@import "./variables/colors";

*::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent !important
}

*::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border-radius: 10px;
  border: 2px solid #F8F8F8;
}

//end of main colors.scss

html {
  box-sizing: border-box;
  overflow-y: hidden;
}
*, *:before, *:after {
  box-sizing: inherit;
}
html, body { height: 100%; }
body {
  margin: 0;
  padding: 0;
}
.mat-body, .mat-body-1, .mat-typography {
  font-size: 12px !important;
  font-family: Mulish;
  font-weight: 500;
}


// Libs Scss
@import 'ngx-toastr/toastr';

// Custom Styles
@import "app";
@import "global/buttons";
@import "global/form";
@import "global/flex";
@import "global/global_classes";
@import "global/input";
@import "global/otp";
@import "global/margin_padding_height_width";
@import "global/material_ovveride";
@import "global/modal_and_cards";
@import "global/texts";



