.admin_container {
  width: 100%;
  min-height: 600px;
  height: 100%;
  background-color: var(--body-color);
  overflow: hidden;
  &--admin_content {
    height: calc(100% - 40px);
    width: 100%;
    & .app_page_top_bar {
      width: 100%;
      &--title {
        &--gray {
          color: var(--enactive-text-color) !important;
        }
        &--slash {
          margin: 0 15px;
        }
      }
      &--right_side_tools {

      }
    }
    &--container {
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }
  }

  // SIDE BAR SCSS AND MEDIA QUERIES FOR SIDE BAR
  @import "partials/sidebar";

  // DESKTOP SIZES
  @import "./variables/desctop_sizes";

  // MEDIA QUERY FOR ADMIN CONTENT
  @media (max-width: (map-get( $desktopSizes , mobile ) - 1)) {
    &--admin_content {
      padding: 30px 20px 20px 20px;
      width: calc(100vw - 235px);
    }
    & .app_page_top_bar {
      text-align: center;
    }
  }

  @media  (min-width: map-get( $desktopSizes , mobile )) and (max-width: (map-get( $desktopSizes , laptop ) - 1)) {
    &--admin_content {
      width: calc(100vw - 80px);
    }
  }
}

