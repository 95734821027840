// Material Autocomplete css changes
.mat-mdc-option {
  white-space: unset !important;;
  overflow: unset !important;;
  text-overflow: unset !important;;
  line-height: unset !important;;
  height: unset !important;
  font-size: 12px !important;
  min-height: 40px;

  .mat-pseudo-checkbox {
    display: none !important;
  }

  &.mdc-list-item--disabled {
    pointer-events: auto !important;
  }



  .mdc-list-item__primary-text {
    width: 100% !important;
    opacity: 1 !important;
  }

  &.mat-mdc-option-multiple {
    &.mat-mdc-option-active {
      background-color: unset !important;
    }

    .mat-pseudo-checkbox {
      display: block !important;
    }

    .mdc-list-item__primary-text {
      color: initial !important;
    }

    .mat-mdc-option-ripple {
      display: none !important;
    }
  }

}


.mat-mdc-select-panel {
  padding: 0 !important;
}

.mdc-list-item--selected {
  .mdc-list-item__primary-text {
    color: var(--light-orange-color) !important;
  }
}

//select

.mat-mdc-select {
  &.mat-select-pagination {
    .mat-mdc-select-arrow {
      display: none !important;
    }

    .mat-mdc-select-value-text {
      font-size: 14px !important;
      line-height: initial;
      font-weight: 600 !important;
    }
  }

  .mat-mdc-select-arrow {
    background-image: url("../../../assets/images/icon/arrow_right_gray.svg");
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    background-size: cover;
    background-repeat: no-repeat;

    svg {
      display: none;
    }
  }
}

//tabs
.mat-mdc-tab {
  .mdc-tab-indicator__content--underline {
    border-width: 2px !important;
    border-color: var(--orange-light-color) !important;
    top: 48px;
    position: absolute;
  }

  & .mdc-tab__ripple, & .mat-ripple {
    display: none !important;
  }


  .mdc-tab__text-label {
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: Mulish !important;
    letter-spacing: normal !important;
    color: var(--text-gray-color) !important;
  }
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--black-color) !important;
}

.login-tab {
  .mdc-tab-indicator {
    display: none !important;
  }

  & .mat-mdc-tab-labels {
    background-color: var(--gray-input);
  }

  & .mat-mdc-tab-header {
    border-radius: 15px;

    & .mat-mdc-tab-label-container {
      & .mdc-tab--active {
        background-color: var(--white-color);
        border-radius: 14px !important;
      }

      & .mat-mdc-tab-labels {
        padding: 4px !important;
        min-width: 186px !important;
        border-radius: 14px;
        color: var(--text-gray-color);
      }
    }
  }

  & .mdc-tab--active .mdc-tab__text-label {
    color: var(--black-color) !important;
  }

  & .mat-mdc-tab {
    .mdc-tab__text-label {
      color: var(--gray-1);
      font-weight: 500 !important;
      font-size: 16px !important;
    }
  }
}

.shop-create-tab, .admin-tab-seller-shop {
  height: -webkit-fill-available !important;

  .mat-mdc-tab-body-content {
    overflow: hidden !important;
  }
}


.admin-tab-seller-shop, .admin_details_tab {
  .mat-mdc-tab-header {
    padding: 0 50px !important;
  }
}

.shop-create-tab, .admin-tab-seller-shop, .admin_details_tab {
  .mat-mdc-tab {
    padding: 0 !important;
    margin-right: 20px;
  }


  .mat-mdc-tab-list {
    border-bottom: 2px solid var(--gray-3);
  }

  & .mat-mdc-tab-labels {
    width: fit-content !important;
  }
}

// end of cards


// Dialog Css

.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface {
    border-radius: 24px !important;
  }
}


//Checkbox
.mdc-checkbox {
  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    background-color: var(--light-orange-color) !important;
  }

  .mdc-checkbox__background {
    border-radius: 6px !important;
    outline: 2px solid var(--gray-2) !important;
    border: 3px solid var(--white-color) !important;
    width: 20px !important;
    height: 20px !important;
  }


  .mat-mdc-checkbox-touch-target, .mdc-checkbox__ripple, .mat-mdc-checkbox-ripple {
    display: none !important;
  }

  .mdc-checkbox__checkmark {
    width: 12px !important;
    top: 1px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}


//Radio button

.mat-mdc-radio-button {
  .mat-mdc-radio-touch-target, .mat-radio-ripple, .mat-mdc-radio-ripple {
    display: none !important;
  }

  .mdc-radio__background::before {
    display: none !important;
  }

  .mdc-radio__outer-circle {
    border-radius: 6px !important;
    outline: 2px solid var(--gray-2) !important;
    border: 11px solid var(--white-color) !important;
  }

  .mdc-radio__inner-circle {
    border-width: 15px !important;
    top: -4px !important;
    left: -4px !important;
    border-radius: 6px !important;
    border-color: var(--light-orange-color) !important;
  }

  .mdc-form-field {
    border-radius: 16px;
    background-color: var(--gray-input);
    padding: 3px 16px 3px 9px;
  }

}


// TOGGLE CHECKBOX
.mat-mdc-slide-toggle {
  transform: scale(1.2);

  .mdc-switch__ripple, .mdc-switch__shadow, .mdc-switch__icon {
    display: none !important;
  }

  .mdc-switch__handle {
    left: 3px;
  }

  .mdc-switch__handle:before {
    display: none !important;
  }

  .mdc-switch__handle:after {
    width: 18px !important;
    height: 18px !important;
    left: -2px !important;
    top: 1px;
    background-color: var(--white-color) !important;
  }

  .mdc-switch__track {
    width: 55px !important;
    height: 20px !important;
    border-radius: 24px !important;
    background: var(--rgb-48-1) !important;
  }
}


.mdc-switch--checked {
  .mdc-switch__handle:after {
    left: -2px !important;
  }

  .mdc-switch__track:after {
    width: 55px !important;
    height: 20px !important;
    border-radius: 24px !important;
    background: var(--orange-light-color) !important;
  }

  .mat-slide-toggle-thumb-container {
    left: 8px !important;
  }
}


.hidden_input {
  height: 0 !important;
  width: 0 !important;
  padding: 0 !important;
}


//CHIP LIST

.mat-mdc-chip-listbox {
  .mat-chip-list-wrapper {
    margin-top: 10px !important;
  }


  .mat-mdc-standard-chip {
    padding: 7.5px 7px;
    min-height: 26px !important;
    color: var(--our-black);
    height: 37px;
    background-color: white !important;
    border-radius: 32px;
    border: 1px solid var(--gray-2);

    .middle_text {
      font-size: 11px;
    }

    .mat-mdc-chip-action {
      padding: 0 !important;
    }

    .mat-mdc-chip-remove {
      background: unset;
      opacity: 1;
      padding: 0 !important;
      margin-top: 2px;
      height: 24px !important;
      width: 24px !important;
      margin-left: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .normal_text {
    font-weight: 600;
  }

  .color_chip_container {
    padding: 3px 0;

    .color_container {
      width: 36px;
      height: 24px;
      border-radius: 20px;
      border: 1px solid var(--gray-3);

      &.background_multiple {
        background: linear-gradient(to bottom,
                rgb(255, 0, 0),
                rgb(255, 225, 0),
                rgb(0, 255, 0),
                rgb(0, 255, 255),
                rgb(0, 0, 255),
                rgb(255, 0, 255),
                rgb(255, 0, 0));
      }
    }

    width: 70px;
  }

  .mat-chip-trailing-icon {
    width: 24px !important;
    height: 24px !important;
  }
}


//ngx-ma-date-time-picker
.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de
}

.mat-calendar-body-selected {
  background-color: #007f51;
  color: #fff;
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, .54);

  th {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
}


.mat-calendar-table-header-divider:after {
  background-color: #0000001f;
}

//date range

.mat-date-range-input-start-wrapper .mat-date-range-input-inner {
  top: 50% !important;
  left: 0 !important;
  transform: translateY(-50%) !important;
}

.mat-date-range-input-end-wrapper, .mat-date-range-input-wrapper {
  max-width: calc(50% - 10px) !important;
  width: 100%;
  font-size: 14px !important;

  input {
    width: inherit !important;
    caret-color: black !important;
    height: -webkit-fill-available !important;
  }
}

.mat-datepicker-toggle {
  .mat-mdc-focus-indicator, .mat-mdc-button-touch-target, .mat-ripple mat-mdc-button-ripple, .mat-mdc-button-persistent-ripple {
    display: none !important;
  }

  .mat-datepicker-toggle-default-icon {
    display: none !important;
  }

  .mat-mdc-focus-indicator, .mat-mdc-button-touch-target, .mat-mdc-button-ripple {
    display: none !important;
    visibility: hidden !important;
  }

  .mdc-icon-button {
    width: 19px !important;
    height: 19px !important;
    padding: 0 !important;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: inherit !important;
    background-size: cover;
    margin-right: 15px;
    background-image: url('../../images/icon/calendar.svg') !important;
  }
}

.mat-date-range-input-wrapper {
  max-width: calc(50% - 38px) !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.mdc-text-field {
  padding: 0 !important;
  background-color: var(--gray-input) !important;
  border-radius: 16px !important;
  overflow: hidden !important;

}

.mdc-notched-outline__leading, .mdc-notched-outline__trailing {
  display: none !important;
}

//end of date range

.cdk-overlay-container:has(.product_shop) .mat-select-panel-wrap {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.cdk-overlay-pane:has(.sort) {
  min-width: 196px !important;
  bottom: 66px !important;
  transform: none !important;
  left: 52px !important;

  div.mat-mdc-select-panel {
    border-radius: 10px !important;
    padding: 0 !important;
  }

  .mat-mdc-option {
    font-size: 13px !important;
    font-weight: 500;
  }
}

.cdk-overlay-pane:has(.pagination_select) {
  bottom: 55px !important;
  min-width: 49px !important;

  .mat-mdc-select {
    .mat-mdc-select-arrow {
      display: none !important;
    }
  }

  div.mat-mdc-select-panel {
    border-radius: 10px !important;
    padding: 0 !important;
  }

  .mat-mdc-option {
    font-size: 13px !important;
    font-weight: 500;
  }

}


.custom-panel-wrap-multiple {
  min-width: calc(100% + 25px) !important;
  left: 24px !important;
  position: absolute;
}

.custom-panel-wrap {
  min-width: 100% !important;
  left: 0 !important;
  position: absolute;
}

.custom-panel-order-select {
  min-width: 100% !important;
  margin-left: 4px !important;
  margin-right: 0;
}

.right_side_modal {
  .mat-dialog-container {
    padding: 40px !important;
    padding-right: 0 !important;
    border-radius: 0 !important;
    overflow: hidden !important;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  padding: 20px;
  background: var(--gray-3);
  border-radius: 20px;

  .added_image {
    width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 20px;

    img {
      object-fit: cover;
    }
  }

  .color_circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;

    &.multiple {
      background: linear-gradient(to bottom,
              rgb(255, 0, 0),
              rgb(255, 225, 0),
              rgb(0, 255, 0),
              rgb(0, 255, 255),
              rgb(0, 0, 255),
              rgb(255, 0, 255),
              rgb(255, 0, 0));
    }
  }

}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
