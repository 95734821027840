&--sidebar {
  height: 100vh;
  background-color: var(--white-color);
  width: 240px;
  min-width: 240px;
  position: relative;
  &--content {
    position: relative;
    min-height: 100%;
    app-sidebar {
      height: 100%;
      overflow-y: unset;
      & .logo_container {
        font-size: 45px;
        margin-left: 20px;
        line-height: initial;
        font-weight: bold;
      }

      & .logo_container_mobile {
        display: none;
        font-size: 20px;
        font-weight: bold;
        line-height: initial;
        text-align: center;
      }

      & .navigations_container {
        height: 77%;
        max-height: calc(100vh - 150px);
        padding-top: 10px;
        &--logo_section {
          margin-bottom: 35px;
          .logo {
            width: 150px;
          }
          .logo_mobile { display: none}
        }
        &--navigation {
          padding: 10px 0 10px 35px;
          &--link {
            min-height: 20px;
            position: relative;
            &--title {
              margin-left: 35px;
              max-width: 60%;
            }
            // Icon
            & .navbar_icon {
              position: absolute;
            }
            & .navbar_icon.inactive {
              width: 15px;
              height: 15px;
            }
            & .navbar_dropdown_icon {
              width: 8px;
              position: absolute;
              right: 10%;
              height: 8px;
              top: 30%;
            }
            & .navbar_icon.active {
              width: 25px;
              display: none;
              left: -5px;
            }
            // Icon End
          }
          &--sub_navigations {
            width: 74%;
            margin-left: 25%;
            &--navigation_item {
              padding-top: 10px;
            }
          }
        }
        & .activeLink {
          & .navigations_container--navigation--link {
            color: var(--orange-color);
            font-weight: 500;
          }
          animation: activeAnimation 0.2s linear;
          background-color: rgb(0,0,0, 0.06);
          & img.inactive { display: none}
          & img.active { display: block}
        }
        @keyframes activeAnimation {
          0% {
            background-color: rgb(0,0,0, 0.0);
          }
          100% {
            background-color: rgb(0,0,0, 0.05);
          }
        }
      }

      & .account_container {
        box-shadow: 0px -3px 6px var(--box-shadow-color);
        width: 100%;
        min-height: 50px;
        background-color: var(--white-color);
        position: absolute;
        bottom: 0;
        .short_name {display: none}
        .time_zone {
          border-top: 1px solid #BEBEBE;
        }
        &--body {
          width: 90%;
          margin: 0 auto;
          position: relative;
          &--account_dropdown {
            position: absolute;
            min-width: 200px;
            bottom: -5px;
            left: 122%;
            background-color: var(--white-color);
            width: 100%;
            height: 150px;
            border-radius: 5px;
            box-shadow: 0px -3px 6px var(--box-shadow-color);
            &--item {
              width: 90%;
              margin: 0 auto;
              height: 30px;
              border-bottom: 1px solid black;
            }
          }

          & .account_img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
          & .name {
            margin-right: 10px;
          }
          & .email {
            color: var(--text-gray-color);
            opacity: 0.8;
          }
        }
      }
    }

    .left_toggle_button { left: 210px !important; }

    app-sidebar-toggle-button {
      display: none;
      left: 15px;
      position: fixed;
      z-index: 20;
      top: 5px;
      & img { width: 30px; }
    }
  }

}

.admin_container--sidebar--content {
  box-shadow: 5px 3px 29px #0000001A;
}


// DESKTOP SIZES
@import "../variables/desctop_sizes";

@media (max-width: (map-get( $desktopSizes , mobile ) - 1)) {
  &--sidebar {
    max-width: 30%;
    width: unset;
    & .navigations_container--navigation {padding: 15px 0 10px 15px;}
    app-sidebar-toggle-button, .logo_container { display: block; }
    app-sidebar {
      display: none;
      max-width: none;
    }

    .logo_mobile {
      display: block;
    }
    .logo_desktop {
      display: none;
    }
  }

  .show_sidebar {
    width: 200px;
    position: absolute;
    z-index: 20;
    background-color: white;
    max-width: none;
    app-sidebar {
      display: block !important;
      & .logo_container_mobile { display: block }
      & .logo_container { display: none }
      & .navbar_dropdown_icon {
        margin: unset;
        padding: 2px;
      }
      & .account_container {
        &--body {
          & .account_img { display: none}
        }
      }
    }
  }
}

// TODO ESI SHCEDULI EJUMA MENAK pti jogenq toxenq te jnjneq
.mobile_sidebar {
  width: 80px;
  min-width: 80px;
  app-sidebar {
    & .navigations_container--logo_section {
      margin-bottom: 20px !important;
      .logo {
        width: 50px;
      }
    }
    & .navigations_container--navigation {padding: 15px 0 10px 5px;}
    & .navigations_container--navigation--sub_navigations {
      position: absolute;
      left: 70px;
      background-color: white;
      box-shadow: 0px -3px 6px var(--box-shadow-color);
      width: 140%;
      z-index: 20;
    }
    & .navigations_container--navigation--sub_navigations--navigation_item {
      padding: 12px 10px;
      top: 10px
    }
    & .navigations_container--navigation--link--title, .logo_container { display: none; }
    & .navbar_icon { left: 35% !important;}
    & .navbar_icon.active { left: 28% !important; }
    & .logo_container_mobile { display: block }
    & .logo_container { display: none }
    & .navbar_dropdown_icon {
      position: absolute;
      right: 10px;
    }
    .logo_mobile {
      display: block !important;
    }
    .logo_desktop {
      display: none;
    }

    & .account_container {
      .short_name { display: block}
      .large_name, .email, .account_img { display: none}
      &--body {
        &--account_dropdown {
          bottom: -5px;
          left: 50px;
          z-index: 100;
        }
      }
    }
  }
}

@media  (min-width: map-get( $desktopSizes , mobile )) and (max-width: (map-get( $desktopSizes , laptop ) - 1)) {
  &--sidebar {
    width: 80px;
    min-width: 80px;
    app-sidebar {
      & .navigations_container--logo_section {
        margin-bottom: 20px !important;
          .logo {
            width: 50px;
          }
      }
      & .navigations_container--navigation {padding: 15px 0 10px 5px;}
      & .navigations_container--navigation--sub_navigations {
        position: absolute;
        left: 70px;
        background-color: white;
        box-shadow: 0px -3px 6px var(--box-shadow-color);
        width: 140%;
        z-index: 20;
      }
      & .navigations_container--navigation--sub_navigations--navigation_item {
        padding: 12px 10px;
        top: 10px
      }
      & .navigations_container--navigation--link--title, .logo_container { display: none; }
      & .navbar_icon { left: 35% !important;}
      & .navbar_icon.active { left: 28% !important; }
      & .logo_container_mobile { display: block }
      & .logo_container { display: none }
      & .navbar_dropdown_icon {
        position: absolute;
        right: 10px;
      }
      .logo_mobile {
        display: block !important;
      }
      .logo_desktop {
        display: none;
      }

      & .account_container {
        .short_name { display: block}
        .large_name, .email, .account_img { display: none}
        &--body {
          &--account_dropdown {
            bottom: -5px;
            left: 50px;
            z-index: 100;
          }
        }
      }
    }
  }
}
