.modal_content {
  flex-direction: column;
  min-height: 100px;
  height: 100%;
  padding: 20px;

  &--content {
    height: 100%;
  }
}

.cdk-global-scrollblock {
  overflow-y: unset !important;
}

.without_padding_modal {
  mat-mdc-dialog-container {
    padding: 0 !important;
  }
}

// Cards
.card {
  background-color: var(--white-color);
  border-radius: 15px;
  margin: 20px 0;
  padding: 20px;
  box-shadow: 0 5px 14px var(--box-shadow-color-bold);
}

.app_card {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 5px 14px var(--box-shadow-color);
  border-radius: 16px;
  padding: 20px;
}

.default_card {
  width: 100% !important;
  padding: 0 20px;
  background: #FCFCFC;
  border-radius: 10px;

  &--title {
    padding: 32px 0 24px;
    font-weight: 800;
    line-height: 28px;
  }

  &--description {
    color: var(--our-black);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    opacity: 0.5;
    width: 500px;
    padding-top: 8px;

  }

  .mat-select::placeholder {
    color: red;
  }
}

.detail_card {
  outline: 1px solid rgba(69, 69, 69, 0.3);
  border-radius: 14px 8px 8px 8px;

  input {
    padding: 0 0 0 10px !important;
    border: unset !important;
    outline: unset !important;
    height: unset !important;
  }

  input[type='text'] {
    padding: 0 0 0 55px !important;
  }
}

@import "../variables/desctop_sizes";

@media (max-width: (map-get( $desktopSizes , medium ) - 1)) {
  .modal_content {
    &--content {
      height: auto;
    }
  }
}

// SHIFT BASE ASSIGNMENT
app-add-shift-template {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

// END OF SHIFT BASE ASSIGNMENT


// Home page login modal
.home_page_container_modal_login {
  mat-mdc-dialog-container {
    border-radius: 20px !important;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  mat-mdc-dialog-container::-webkit-scrollbar {
    width: 0;
  }
}

.unread_messages_count {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  width: 24px;
  height: 20px;
  padding: 4px;
  background-color: #FD4C3B;
  border-radius: 60px;
  text-align: center;
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
