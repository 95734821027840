// Texts
.title_text {
  font-size: 28px;
  font-weight: bold;
  line-height: initial;

}

.text-title {
  font-size: 32px;
  font-weight: 800;
  line-height: 44px;
}

.title_text_big {
  font-size: 54px;
  font-weight: bold;
  line-height: initial;
}

.title_normal_text {
  font-size: 18px;
  font-weight: 600;
  line-height: initial;
}

.text_size_24 {
  font-size: 24px;
  line-height: initial;
}

.normal_text {
  font-size: 12px;
  line-height: initial;
}

.sub_title_text {
  font-size: 20px;
  font-weight: 700;
  line-height: initial;
  color: var(--our-black);
}

.big_title_text {
  font-size: 40px;
  font-weight: bold;
  line-height: initial;

}

.middle_text {
  font-size: 16px;
  line-height: initial;
  font-weight: 600;
}

.middle_small_text {
  font-size: 14px;
  line-height: initial;
  font-weight: 600;
}

.small_text {
  font-size: 10px;
  line-height: initial;
}


.bold {
  font-weight: 500;
}

.bold_800 {
  font-weight: 800;
}

.bold_700 {
  font-weight: 700;
}

.bold_600 {
  font-weight: 600;
}

// End of Texts

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.break_all {
  word-break: break-all;
}

.pointer {
  cursor: pointer !important;
}

.error_message {
  color: var(--orange-color);

  &.active {
    animation: active 0.5s ease-in;
    @keyframes active {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.center_text {
  text-align: center;
}

.color_gray_text {
  color: var(--gray-1) !important;
}

.bordo_color {
  color: #B22113 !important;
}
