.main_buttons {
    outline: unset;
    border: unset;
    padding: 10px 50px;
    cursor: pointer;
    color: var(--white-color);
    border-radius: 16px;
    width: initial;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

  &.gradient_btn {
    background-image: linear-gradient(to right, #FE511A, #F83873);
    &:active {
      color: #FFFFFFA3;
    }
  }


  &:disabled {
    background-image: unset;
    border:none !important;;
    cursor: unset !important;
    background-color: rgb(169, 169, 169) !important;
  }

  &.cancel_btn {
    height: 44px;
    border-radius: 16px ;
    color: black;
    font-weight: 600;
    background-color: white;
    border: 1px solid var(--gray-2);
  }
  &.btn_cancel {
    color: #373737a3;
    font-weight: 600;
    background-color: white;
  }

  & .dark_btn {
    background: #000000 0% 0% no-repeat padding-box;
  }

  &.default_btn {
    background-color: transparent;
    border-color: transparent;
    font:  bold 18px/24px Segoe UI;
    color: #000000;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  &.small {
    font:  600 14px/19px Segoe UI;
    letter-spacing: 0;
    padding: 4px 24px;
    opacity: 1;
  }
  &.white_btn {
    color: #FB444B;
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid #FA4250;
    border-radius: 28px;
    padding: 4px 17px;
    opacity: 1;
    font:  600 14px/19px Segoe UI;
    letter-spacing: 0;
    &.active {
      background: transparent linear-gradient(119deg, #FE511A 0%, #F83873 100%) 0 0 no-repeat padding-box;
      border-radius: 28px;
      opacity: 1;
      border: unset;
      font:  600 14px/19px Segoe UI;
      letter-spacing: 0;
      color: #FFFFFF;
    }
  }
  &.story_btn {
    background: #FFFFFF 0 0 no-repeat padding-box;
    border-radius: 20px;
    padding: 10px 33px;
    font:  600 16px/21px Segoe UI;
    letter-spacing: 0;
    color: #000000;
    @media (max-height: 650px ){
      padding: 5px 22px;
      font: 600 12px Segoe UI;
    }
  }
  &:hover,&:active,&:focus {
    outline: 0;
    box-shadow: none;
    cursor: pointer;
  }
    &:disabled {
      opacity: 0.5;
    }

    &--loader {
      height: 24px;
    }

    &:hover {
      & .main_buttons--loader {
        filter: invert(1);
      }
    }
}
.black_btn {
  background-color: var(--black-color);
  font-weight: bold;
  &:hover {
    background-color: #E9E7E7;
    color: var(--black-color);
    font-weight: bold;
  }
}

.white_black_btn {
  border: 1px solid var(--black-color);
  color: var(--black-color);
  background-color: var(--white-color);

  &:hover {
    background-color: var(--black-color);
    color: var(--white-color);
  }
}

.gray_btn {
  background-color: var(--gray-color);
  color: var(--black-color);
  font-weight: 600;
}

.cancel_btn {
  background-color: transparent;
  color: var(--gray-color);
}

.red_btn {
  background-color: var(--input-red);
  border-radius: 20px;
  transition: all 0.2s;
  border: 2px solid  var(--input-red);
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
  }

  &:hover {
    background-color: var(--white-color);
    color: var(--input-red);
    transition: all 0.2s;
  }
}

.white_red_btn {
  color: var(--red-color);
  &:hover {
    background-color: var(--white-color);
    color: var(--red-color);
    border-color: var(--red-color);
  }
}

.green_btn {
  background-color: var(--green-color);
  &:hover {
    background-color: var(--white-color);
    color: var(--green-color);
    border-color: var(--green-color);
  }
}

.white_green_btn {
  border: 1px solid var(--green-color);
  color: var(--green-color);
  background-color: var(--white-color);

  &:hover {
    background-color: var(--green-color);
    color: var(--white-color);
  }
}

.blue_btn {
  background-color: var(--blue-color);
  &:hover {
    background-color: var(--white-color);
    color: var(--blue-color);
    border-color: var(--blue-color);
  }
}

.white_green_btn {
  background-color: var(--white-color);
  border-color: var(--green-color);
  color: var(--green-color);
  &:hover {
    background-color: var(--green-color);
    color: var(--white-color);
  }
}

.orange_btn {
  border: 1px solid var(--bright-red-color);
  background: var(--bright-red-color);
  color: var(--white-color);
  transition: all 0.2s;
  border-radius: 16px;
  padding: 14px 50px;
  border-radius: 20px !important;

  &:disabled {
    background-color: var(--bright-red-color) !important;
    border: var(--bright-red-color) !important;
  }

  &:hover {
    background: var(--white-color);
    color: var(--bright-red-color);
    transition: all 0.2s;
  }

  &:disabled {
    color: var(--white-color);
  }
}



.loader-padding {
  padding: 6px 50px;
}

.small_button {
  padding: 8px 30px;
}
.small_button_big_padding {
  width: 275px;
  height: 42px;
  position: relative;
  &--loader {
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.button_w_180{
  width: 180px !important;
}

.small_button.loader-padding {
  padding: 5px 30px 2px !important;
  & .main_buttons--loader {
    height: 20px;
  }
}

.close_image {
  width: 16px;
  height: 16px;
}
.actions {
  width: unset !important;
  padding: 8px 15px !important;
}

.full_width {
  width: 100% !important;
  padding: 18px 15px !important;
  border-radius: 16px !important;
  height: fit-content;
}
// DESKTOP SIZES
@import "../variables/desctop_sizes";

@media (min-width:  map-get( $desktopSizes , desktop-lg )) {
  .main_buttons {
    padding: 10px 16px;
    font-size: 16px;
  }
}
