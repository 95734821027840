.float-r {
  float: right;
}

// Margins
.mt-10 { margin-top: 10px !important;}
.mt-12 { margin-top: 12px !important;}
.mt-40 { margin-top: 40px !important;}
.mt-5 { margin-top: 5px !important;}
.mt-4 { margin-top: 4px !important;}
.mb-5 { margin-bottom: 5px !important;}
.mb-30 { margin-bottom: 30px !important;}
.mb-20 { margin-bottom: 20px !important;}
.mb-15 { margin-bottom: 15px !important;}
.ml-5 { margin-left: 5px !important;}
.mb-10 { margin-bottom: 10px !important;}
.mb-16 { margin-bottom: 16px !important;}
.mt-20 { margin-top: 20px !important;}
.mt-32 { margin-top: 32px !important;}
.mt-16 { margin-top: 16px !important;}
.ml-16 { margin-left: 16px !important;}
.mt-30 { margin-top: 30px !important;}
.ml-20 { margin-left: 20px !important;}
.ml-12 { margin-left: 12px !important;}
.ml-8 { margin-left: 8px !important;}
.ml-4 { margin-left: 4px !important;}
.ml-5 { margin-left: 5px !important;}
.ml-2 { margin-left: 2px !important;}
.ml-30 { margin-left: 30px !important;}
.ml-40 { margin-left: 40px !important;}
.ml-10 { margin-left: 10px !important;}
.mr-20 { margin-right: 20px !important;}
.mr-8 { margin-right: 8px !important;}
.mr-10 { margin-right: 10px !important;}
.m-rl-80{
  margin: 0 50px;
}
.m-auto {margin: 0 auto !important;}
.ml-auto {margin-left: auto}
.input-ml-20 {margin-left: 0 !important;}


.input-ml-items-3 { margin-left: calc(10% / 2 )}
// End of Margins
// Paddings
.pl-10 { padding-left: 10px !important; }
.pl-20 { padding-left: 20px !important; }
.pr-10 {padding-right: 10px !important;}
.pr-40 {padding-right: 40px !important;}
.pr-20 {padding-right: 20px !important;}
.pr-80 {padding-right: 80px !important;}
.pb-20 {padding-bottom: 20px !important;}
.pb-10 {padding-bottom: 10px !important;}
.pt-10 {padding-top: 10px !important;}
.pt-15 {padding-top: 15px !important;}
.pt-20 {padding-top: 20px !important;}
.pt-32 {padding-top: 32px !important;}
.p-10 {padding: 10px !important;}
.p-20 {padding: 20px !important;}
.p-30 {padding: 30px !important;}
.p-rl-10 { padding: 0 10px; }
.p-rl-20 { padding: 0 20px; }
.p-rl-30 { padding: 0 30px; }
.p-rl-80 { padding: 0 50px; }

.padding_unset {
  padding: unset;
}
// End of Paddings

.w-100 {width: 100%}
.w-70 {width: 70%}
.h-100 {height: 100%}
.h-50 {height: 50%}
.h-100-minus-55 { height: calc(100% - 55px);}
.h-100-minus-40 { height: calc(100% - 40px);}
.vh-100-minus-40 { height: calc(100vh - 150px);}
.vh-100-minus-155 { height: calc(100vh - 155px);}
.vh-100-minus-190 { height: calc(100vh - 190px);}
.h-w-100-no-overflow {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.w-imp-100 {width: 100% !important;};
.h-imp-100 {height: 100% !important;};
.w-20 {width: 20%}
.w-25 {width: 25% !important;}
.w-35 {width: 35% !important}
.w-30 {width: 30% !important;}
.w-40 {width: 40%}
.w-45 {width: 45% !important;}
.w-48 {width: 48% !important;}
.w-50 {width: 50% !important;}
.w-60 {width: 60% !important;}
.w-65 {width: 65% !important;}
.w_60_max {
  max-width: 60%
}
.w-70 {width: 70%}
.w-80 {width: 80% !important;}
.vw-70 {
  width: 70vw;
}
.vh-90 {
  height: 90vh !important;
}
@import "../variables/desctop_sizes";

@media (max-width: (map-get( $desktopSizes , medium ) - 1)) {
  .w-tablet-100 { width: 100% !important;}
  .mt-tablet-20 { margin-top: 20px;}
}
